<template>
  <div id="orderList" class="orders">
    <Refresh show_unbind="1" />
    <div>
      <ul>
        <li class="orders-item" v-for="order in orders" :key="order.id" @click="send(order,order.id)">
          <div class="orders-item-left"><img :src="order.logo" /></div>
          <div class="orders-item-right">
            <div class="orders-item-title">{{ order.job_title }}</div>
            <div class="orders-item-label"><span v-for="label in order.label_data" :key="label">{{ label }}</span></div>
            <div class="orders-item-price" ><span class="price">{{order.start_salary}}-{{order.end_salary}}</span><span class="text">元/月</span>
              <!--            <van-button @click="send(order.id)" class="send" type="primary">分享</van-button>-->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {Toast} from "_vant@2.12.53@vant";
import {getOrderList} from "../../api/test";
import {login} from "../../api/test";
import {checkBind, setEnterPath,checkLoadWxConfig} from "../../utils/base";
import Refresh from "@/components/home/refresh.vue";

const wx = window.wx;

export default {
  components: {
    Refresh
  },
  data() {
    return {
      orders: [],
    }
  },
  mounted() {

    console.log('test333');

    // setEnterPath('/order/index');

    // 判断绑定
    if (!checkBind(this.$router)) {
      return false;
    }

    // 判断初始化
    if (!checkLoadWxConfig(this.$router)) {
      return false;
    }

    // 获取订单
    this.getOrderList();
  },
  methods: {
    async getOrderList() {
      await getOrderList({type:0}).then((res) => {
        if (res.code == 200) {
          this.orders = res.data.data;
        }
      })
    },
    send(order,orderId) {
      // console.log(order,orderId,'miniprogram')
      wx.invoke('sendChatMessage', {
        msgtype:"miniprogram", //消息类型，必填
        enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
        miniprogram:
            {
              appid: "wx3bb67ab45cae2155",//小程序的appid，企业已关联的任一个小程序
              title: order.job_title, //小程序消息的title
              imgUrl: order.logo,//小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
              page: `pages/home/pages/details/details.html?id=${orderId}`, //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
            },
      }, function(res) {
        if (res.err_msg == 'sendChatMessage:ok') {
          //发送成功
        }
      })
    }
  }
}
</script>

<style>
.orders img{
  border-radius: 3px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  position: relative;
}
.orders-item {
  padding: 20px 10px;
  clear: both;
}
.orders-item-left {
  float: left;
}
.orders-item-right {
  float: left;
  margin-left: 10px;
}
.orders-item-title {
  font-size: 1.2em;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.orders-item-label {
  margin-top: 10px;
}
.orders-item-label span{
  margin-right: 5px;
  font-size: 1em;
  color: #1F97EE;
  background-color: #E9F5FF;
}
.orders-item-price {
  margin-top: 10px;
}
.orders-item-price .price{
  color: #FF4E3A;
  font-weight: bold;
  font-size: 1.2em;
}
.orders-item-price .text {
  color: #A2A2A2;
}
.orders-item .send {
  height: auto;
  padding: 3px 15px;
  margin-left: 10px;
}
</style>
